import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "../pages/home/home";
import ProductDetail from "../pages/product/product-detail/productDetail";
import News from "../pages/news/news";
import NewsDetail from "../pages/news/news-detail/newsDetail";
import About from "../pages/about/about";
import notFound from "../pages/404/notFound";
import ProductHome from "../pages/product/product-home/productHome";
import request from "../util/request";
import custom from "../pages/custom/custom";


export class WkRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routeList: [

                {
                    menuId: 6,
                    parentId: 5,
                    name: '新闻详情',
                    exact: false,
                    enName: 'newsDetail',
                    path: '/news/detail/:id',
                    params: '',
                    component: 'NewsDetail'
                }
            ]
        }
    }

    initRouters(){
        request({
            url: '/dubai/query/route',
            method: 'get',
        }).then(res=>{
            console.log('获取路由信息成功',res)
            this.setState({
                routeList: res.data
            })
        })
    }

    componentDidMount() {
        this.initRouters()
    }

    render() {
        const {routeList} = this.state
        return (
            <div>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/product' component={ProductHome}/>
                    <Route path='/product/detail/:id' component={ProductDetail}/>
                    <Route path='/about' component={About}/>
                    <Route exact path='/news' component={News}/>
                    <Route path='/news/detail/:id' component={NewsDetail}/>
                    {
                        routeList.map((item, index) => {
                            return (
                                <Route path={item.path} exact={item.parentId === 0} component={custom} key={index}/>
                            )
                        })
                    }
                    <Route path='*' component={notFound}/>
                </Switch>
            </div>
        );
    }
}
