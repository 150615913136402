import React, {Component} from 'react';
import NewsBox from "../../news/news-box/newsBox";
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";

class News extends Component {
    state = {
        newsData: [
            {
                newsId: 1,
                title: 'loading',
                enTitle: 'loading',
                description: 'loading',
                enDescription: 'loading',
                createTime: 'loading',
                fileVOList: [
                    {
                        fileId: 1,
                        url: 'loading'
                    }
                ]
            },
        ],
        currentPage: 1,
        itemsPerPage: 3,
        isEnglish: true
    };
    handlePageChange = (page) => {
        this.setState({ currentPage: page });
    };

    initNews(){
        return request('/dubai/query/news/home', {
            method: 'GET'
        })
    }

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initNews().then(res => {
            console.log('newsData', res)
            if (res.code === 200) {
                this.setState({
                    newsData: res.data
                })
            }
        })
    }

    render() {
        const { newsData, currentPage, itemsPerPage } = this.state;

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentNewsData = newsData.slice(startIndex, endIndex);
        return (
            <div style={{
                marginTop: '20px'
            }}>
                {this.state.isEnglish ?
                    currentNewsData.map((news,index) => (
                    <NewsBox key={index} id={news.newsId} title={news.enTitle} intro={news.enDescription} createTime={news.createTime} imageUrl={news.fileVOList[0].url}/>
                    ))
                    :
                    currentNewsData.map((news,index) => (
                    <NewsBox key={index} id={news.newsId} title={news.title} intro={news.description} createTime={news.createTime} imageUrl={news.fileVOList[0].url}/>
                ))}
            </div>
        );
    }
}

export default News;
