import React, {Component} from 'react';
import {Carousel, Image} from 'antd';
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";

const contentStyle = {
    height: '700px',
    width: '1920px',
    textAlign: 'center',
    background: '#000000',
    opacity: '1',
};
/*文字浮在图片上面，并且有蒙版遮住图片*/
const textStyle = {
    position: 'absolute',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#fff',
    fontSize: '50px',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    top: '10%',
    width: '20%',
    marginLeft: '60px',
    zIndex: 2
}


class Banner extends Component {
    initBanner(){
        return request('/dubai/query/banner', {
            method: 'GET',
        })
    }
    constructor(props) {
        super(props);
        this.state = {
            isEnglish: true,
            imgList: [
                {
                    bannerId: 1,
                    title: '',
                    description: '',
                    fileVOList: [
                        {
                            fileId: 1,
                            url: '',
                        }
                    ]
                }
            ]
        };
        this.initBanner = this.initBanner.bind(this)
    }
    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initBanner().then(res => {
            this.setState({
                imgList: res.data
            })
        })
    }

    render() {
        const {isEnglish} = this.state;
        return (
            <Carousel autoplay>
                {
                    this.state.imgList.map((item, index) => {
                      return (
                          <div key={index}>
                              <div style={textStyle}>{isEnglish?item.enTitle:item.title}</div>
                              <div style={contentStyle}>
                                  <Image preview={false}  width={'1920px'} height={"720px"} src={item.fileVOList[0].url} />
                              </div>
                          </div>
                      )
                    })
                }
            </Carousel>
        );
    }
}

export default Banner;
